html,body, div#root { 
  height:100%; 
  margin: 0;
}

div#root { 
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}